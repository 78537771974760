<template>
    <div class="login-pages">
        <el-row>
            <el-col :span="12">
                <div class="left-login">
                    <div class="top-login-logo">
                        <img src="./../../../public/img/logo-mini.png" />
                    </div>
                    <div class="center-login-img">
                        <!-- <img src="../../../public/img/svg/logon-fon.svg" alt="" /> -->
                    </div>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="right-login">
                    <el-form
                        ref="form"
                        :model="form"
                        class="theme-form login-form"
                        :rules="rules"
                    >
                        <h4>Kirish</h4>
                        <!-- <h6>Welcome back! Log in to your account.</h6> -->

                        <el-form-item label="Telefon" prop="phone_number">
                            <el-input
                                placeholder="998"
                                v-model="form.phone_number"
                            >
                                <template slot="prepend">
                                    <i class="el-icon-message"></i>
                                </template>
                            </el-input>
                        </el-form-item>

                        <el-form-item label="Пароль" prop="password">
                            <el-input
                                placeholder="Пароль"
                                v-model="form.password"
                                show-password
                            >
                                <template slot="prepend">
                                    <i class="el-icon-lock"></i>
                                </template>
                            </el-input>
                        </el-form-item>

                        <!-- <div class="login autr">
                            <el-checkbox v-model="checked">
                                Parol eslab qolish
                            </el-checkbox>

                            <router-link class="link" :to="{ name: 'login' }">
                                Parol unutdingizmi?
                            </router-link>
                        </div> -->

                        <el-form-item>
                            <el-button
                                type="primary"
                                class="btn-primary2 mtf20 w-100"
                                @click="login()"
                                :loading="loading"
                            >
                                {{ $t("message.login") }}
                            </el-button>
                        </el-form-item>

                        <!-- <div class="login-form-fotter">
                            <p>
                                Akkauntingiz yoqmi?
                                <router-link
                                    class="link"
                                    :to="{ name: 'register' }"
                                >
                                    Akkaunt yaratish
                                </router-link>
                            </p>
                        </div> -->
                    </el-form>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
// @ is an alias to /src

import { mapActions, mapGetters } from "vuex";
export default {
    name: "login",
    data() {
        return {
            user: [],
            phone_number: "",
            checked: false,
            form: {
                password: "",
                phone_number: "+998",
            },

            validCredentials: {
                username: "lightscope",
                password: "lightscope",
            },
            valid: {
                phone_number: true,
                confirm_code: false,
                password: false,
            },
            model: {
                valid_phone: "",
                confirm_code: null,
                password: "",
                confirm_password: "",
            },
            loading: false,
            loading_validate: false,
            dialogVisible: false,
            rules: {
                phone_number: [
                    {
                        required: true,
                        message: "Пожалуйста, введите телефон",
                        trigger: "blur",
                    },
                    {
                        min: 13,
                        max: 13,
                        message: "Длина должна быть 13",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "Пожалуйста, введите телефон",
                        trigger: "blur",
                    },
                    {
                        min: 5,
                        message: "Длина должна быть 5",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    watch: {
        $route: {
            handler: function (route) {
                const query = route.query;
                if (query) {
                    this.redirect = query.redirect;
                    this.otherQuery = this.getOtherQuery(query);
                }
            },
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            token: "auth/token",
        }),
    },
    mounted(){
        window.addEventListener('keyup', e => {
            if (e.keyCode === 13) {
                e.preventDefault();
                this.login();
            }
        });
    },
    methods: {
        ...mapActions({
            postLogin: "auth/login",
        }),
        getOtherQuery(query) {
            return Object.keys(query).reduce((acc, cur) => {
                if (cur !== "redirect") {
                    acc[cur] = query[cur];
                }
                return acc;
            }, {});
        },
        login() {
            if (!this.loading) {
                this.loading = true;
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        this.postLogin(this.form)
                            .then((res) => {
                                this.loading = false;
                                this.$router.push({
                                    path: this.redirect || "/",
                                    query: this.otherQuery,
                                });
                            })
                            .catch((error) => {
                                this.$alert(error);
                                this.loading = false;
                            });
                    }
                });
            }
        },
        Message_Success(message) {
            this.$notify.success({
                dangerouslyUseHTMLString: true,
                title: "Success",
                message: "<strong>" + message + "</strong>",
                duration: 6000,
            });
        },
        Message_Error(code, message) {
            this.$notify.error({
                dangerouslyUseHTMLString: true,
                title: "Error " + code,
                message: "<strong>" + message + "</strong>",
                position: "top-right",
            });
        },
    },
};
</script>
<style lang="scss">
.login-pages {
    width: 100%;
    display: block;
    .left-login {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        height: 100vh;
        .center-login-img {
            display: flex;
            flex: 1;
            justify-content: center;
            img {
                width: 80%;
            }
        }
    }

    .right-login {
        background-color: #24695c1a;
        height: 100vh;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 100vh;
        margin: 0 auto;
        padding: 30px 12px;
    }
}
.login-text {
    font-size: 28px;
    margin-bottom: 20px;
}
.assaosi-color {
    border-color: #7367f0 !important;
    background-color: #7367f0 !important;
    // border-radius: 50px;
    width: 100%;
}

.login-form {
    padding: 30px;
    width: 450px;
    background-color: #fff;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.login-form h4 {
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 22px;
}
.login-form h6 {
    font-size: 14px;
    margin: 0;
    margin-bottom: 20px;
    color: #999;
}
.login.autr .el-checkbox__label,
.link {
    font-weight: 500;
    color: #24695c;
    text-decoration: none;
    font-size: 16px;
}
.login.autr {
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-between;
}
.login-form .el-form-item {
    margin-bottom: 10px;
}
.login-form-fotter p {
    margin-top: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: unset;
    color: #212529;
}
.login-form .el-input-group__prepend {
    background-color: #24695c1a;

    font-size: 16px;
    color: #24695c;
}
.login-form .el-form-item__label {
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: -5px;
    color: #212529;
}
.vaqt {
    color: #fff;
    font-weight: 600;
}
.mtf20 {
    margin-top: 20px;
}
</style>
